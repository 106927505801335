<template>

<div class="homepage">
    <Header title="Administrator Panel"> </Header>
    
    <div class="row">
        <div class="col-6 text-left">
          <div class="lako text-center" @click="gotoPage('/admin/users')">
              <i class="fas fa-user"></i>
              <h2>Lako Users</h2>
              </div>
        </div>
        <div class="col-6 text-left" @click="gotoPage('/admin/customers')">
          <div class="lako text-center">
             
              <i class="fas fa-users"></i>
              <h2>Customers / Contacts</h2>
              </div>
        </div>
        <div class="col-6 text-left" @click="gotoPage('/admin/manage-kbase')">
          <div class="lako text-center">
              <i class="fas fa-book"></i>
              <h2>Manage KBase</h2>
              </div>
        </div>
        <div class="col-6 text-left" @click="gotoPage('/admin/all-requests')">
          <div class="lako text-center">
               <i class="fas fa-question"></i>
              <h2>All Requests</h2>
              </div>
        </div>
    </div>
</div>

</template>

<script>
import Header from '@/components/Header'
export default {
    components: {Header},
    
    methods:{
        gotoPage: function(pageName){
          console.log(pageName)
          
          this.$router.push(pageName)
      }
    }
}
</script>


<style scoped>
.col-6.text-left {
    float: left;
    width: 50%;
    margin-top: 20px;
    margin-bottom:130px;
}


.fas {
    font-size: 2rem;
    color: maroon;
    margin-bottom: 11px;
}
</style>
